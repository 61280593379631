import * as React from "react";
import styled from "styled-components";

export default class Footer extends React.Component<{}, {}> {
  render() {
    return (
      <div className="container px-6 py-6 text-sm text-grey-darker">
        &copy;2018 Mark Bao. All rights reserved. Mark Bao Consulting is a
        division of The Bao Group LLC.
      </div>
    );
  }
}
