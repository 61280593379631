import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import "../styles/normalize.css";
import "../styles/index.css";
import Footer from "./footer";

interface IProps {
  children: any;
  pageName: string | null;
  fullWidth?: boolean;
  indexPage?: boolean;
  description?: string;
  ogImage?: any;
}

export default class Layout extends React.Component<IProps, {}> {
  render() {
    const {
      children,
      pageName,
      fullWidth,
      indexPage,
      description,
      ogImage
    } = this.props;

    let title = "Mark Bao Consulting";
    if (pageName) {
      title = `${pageName} \u2013 ` + title;
    }

    let containerClassName = "container px-6 mx-auto";
    if (fullWidth) {
      containerClassName = "";
    }

    const meta = [
      {
        name: "description",
        content:
          description ||
          "Design and development of custom software to solve business problems, improve processes, and introduce new products to market."
      },
      {
        name: "title",
        property: "og:title",
        content: title
      }
    ];

    if (ogImage) {
      meta.push({
        name: "og:image",
        property: "og:image",
        content: ogImage
      });
    }

    return (
      <>
        <Helmet title={title} meta={meta} />
        <Header indexPage={indexPage} />
        <div className={containerClassName}>{children}</div>
        <Footer />
      </>
    );
  }
}
