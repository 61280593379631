import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

interface IProps {
  indexPage?: boolean;
}

export default class Header extends React.Component<IProps, {}> {
  render() {
    let className = "container px-6";
    if (this.props.indexPage) {
      className = "index-container";
    }

    return (
      <>
        <HeaderContainer>
          <div className={className}>
            <HeaderBrand>
              <HeaderBrandLink to={"/"}>Mark Bao Consulting</HeaderBrandLink>
            </HeaderBrand>
            <HeaderMenu>
              <HeaderMenuSection>
                <HeaderMenuSectionTitle>Menu</HeaderMenuSectionTitle>
                <HeaderMenuSectionItems>
                  <MenuItem to="/" exact={true}>
                    Home
                  </MenuItem>
                  <MenuItem to="/work" activeClassName="active">
                    Work
                  </MenuItem>
                  <MenuItem to="/contact">Contact</MenuItem>
                </HeaderMenuSectionItems>
              </HeaderMenuSection>
              <HeaderMenuSection>
                <HeaderMenuSectionTitle>Specializations</HeaderMenuSectionTitle>
                <HeaderMenuSectionItems>
                  <MenuItem to="/internal-tools">Internal Tools</MenuItem>
                  <MenuItem to="/apps">B2B/B2C Apps</MenuItem>
                </HeaderMenuSectionItems>
              </HeaderMenuSection>
            </HeaderMenu>
          </div>
        </HeaderContainer>
      </>
    );
  }
}

interface IMenuItemProps {
  to: string;
  children: string;
  activeClassName?: string;
  exact?: boolean;
}

// export class IMenuItemProps =
const MenuItem: React.SFC<IMenuItemProps> = props => (
  <HeaderMenuItem>
    <HeaderMenuItemLink
      to={props.to}
      activeClassName={props.activeClassName || "active"}
      exact={props.exact}
    >
      {props.children}
    </HeaderMenuItemLink>
  </HeaderMenuItem>
);

const HeaderContainer = styled.div.attrs({
  className: "pt-6"
})`
  //border-bottom: 2px solid #e2dcfa;
`;

const HeaderBrand = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1rem;
`;

const HeaderBrandLink = styled(Link).attrs({
  className: "text-brand font-bold text-xl"
})``;

const HeaderMenu = styled.div.attrs({
  className: "mt-6"
})``;

const HeaderMenuSection = styled.div.attrs({
  className: "inline-block"
})`
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-right: 0;
  }
`;

const HeaderMenuSectionTitle = styled.div.attrs({
  className: "text-xs font-bold uppercase mb-1"
})`
  color: #b5b5b5;
`;

const HeaderMenuSectionItems = styled.ul.attrs({
  className: "text-xl"
})`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const HeaderMenuItem = styled.li.attrs({
  className: "inline-block mr-6"
})``;

const HeaderMenuItemLink = styled(Link).attrs({})`
  color: #6a6a6a;
  transition: color 0.1s ease-out;

  &.active {
    color: #6e50e6;
  }

  &:hover {
    color: #6e50e6;
  }
`;

const HeaderMenuItemA = styled.a.attrs({})`
  color: #6a6a6a;
  transition: color 0.1s ease-out;

  &.active {
    color: #6e50e6;
  }

  &:hover {
    color: #6e50e6;
  }
`;
